<template>
  <div class="app-container">
    <div class="filter-container">
      <el-radio-group v-model="listQuery.type" class="filter-item" size="small" @change="handleFilter">
        <el-radio-button :label="1">外卖</el-radio-button>
        <el-radio-button :label="2">商城</el-radio-button>
      </el-radio-group>
    </div>
    <div class="filter-container">
      <el-button class="filter-item" type="primary" size="small" icon="el-icon-edit" @click="handleAdd">添加</el-button>
    </div>
    <div class="filter-container">
      <el-button-group>
        <el-button type="primary" size="small" @click="handleShow(1)" :disabled="ids.length <= 0">批量上架</el-button>
        <el-button type="warning" size="small" @click="handleShow(0)" :disabled="ids.length <= 0">批量下架</el-button>
        <el-button type="danger" size="small" @click="handleDel(null)" :disabled="ids.length <= 0">批量删除</el-button>
      </el-button-group>
    </div>
    <el-table v-loading="listLoading" :data="list" @selection-change="handleSelectionChange" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column type="selection" align="center" width="60"></el-table-column>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.school?scope.row.school.school_name:'' }}
        </template>
      </el-table-column>
      <el-table-column label="类型" min-width="100" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type === 1" type="primary">外卖</el-tag>
          <el-tag v-else-if="scope.row.type === 2" type="warning">商城</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="大标题" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.big_title }}
        </template>
      </el-table-column>
      <el-table-column label="小标题" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.small_title }}
        </template>
      </el-table-column>
      <el-table-column label="图片" min-width="100" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.img" class="user-avatar">
        </template>
      </el-table-column>
      <el-table-column label="权重" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.weight }}
        </template>
      </el-table-column>
      <el-table-column label="跳转类型" min-width="100" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.link_type == 0">不跳转</span>
          <span v-else-if="scope.row.link_type == 1">店铺</span>
          <span v-else-if="scope.row.link_type == 2">手机号</span>
          <span v-else-if="scope.row.link_type == 3">推文</span>
          <span v-else-if="scope.row.link_type == 4">图片</span>
        </template>
      </el-table-column>
      <el-table-column label="链接" min-width="120" align="center">
        <template slot-scope="scope">
          {{ scope.row.link }}
        </template>
      </el-table-column>
      <el-table-column label="是否上架" min-width="100" align="center">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.is_active == 0">下架</el-tag>
          <el-tag type="primary" v-else>上架</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="280" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button type="danger" size="mini" @click="handleDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-position="left" label-width="70px">
        <el-form-item label="类型" prop="type">
          <el-radio-group v-model="form.type" disabled>
            <el-radio-button :label="1">外卖</el-radio-button>
            <el-radio-button :label="2">商城</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="大标题" prop="big_title">
          <el-input v-model="form.big_title" placeholder="请输入大标题" />
        </el-form-item>
        <el-form-item label="小标题" prop="small_title">
          <el-input v-model="form.small_title" placeholder="请输入小标题" />
        </el-form-item>
        <el-form-item label="图片" prop="img">
          <upload-one v-model="form.img"></upload-one>
          <div style="font-size:12px;color:red;">
            *推荐尺寸：375*225
          </div>
        </el-form-item>
        <el-form-item label="权重" prop="weight">
          <el-input v-model="form.weight" type="number" min="0" placeholder="权重值越大，排序越靠前" />
        </el-form-item>
        <el-form-item label="跳转类型" prop="link_type">
          <el-select class="filter-item" v-model="form.link_type" placeholder="请选择跳转类型" style="width: 140px">
            <el-option label="店铺" :value="1"></el-option>
            <el-option label="手机号" :value="2"></el-option>
            <el-option label="推文" :value="3"></el-option>
            <el-option label="图片" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="链接" prop="link">
          <el-input v-model="form.link" placeholder="请输入链接" v-if="form.link_type!==4" />
          <div v-else>
            <upload-one v-model="form.link"></upload-one>
            <div style="font-size:12px;color:red;">
              *推荐尺寸：375*667
            </div>
          </div>
          <div style="line-height:16px;font-size: 12px;color:red;margin-top:10px;">
            <div>*店铺，此处填店铺id</div>
            <div>*手机号，此处填要拨打的手机号</div>
            <div>*推文，此处填链接url，需在微信小程序管理平台添加该业务域名</div>
            <div>*图片，此处上传一张图片</div>
          </div>
        </el-form-item>
        <el-form-item label="是否上架">
          <el-switch v-model="form.is_active" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
        type: 1,
      },
      dialogVisible: false,
      btnLoading: false,
      form: {
        id: "",
        school_id: "",
        type: "",
        big_title: "",
        small_title: "",
        img: "",
        link_type: 1,
        link: "",
        weight: 0,
        is_active: 1
      },
      ids: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
    },
    dialogVisible(newVal, oldVal) {
      // 编辑框一异隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: "",
          school_id: "",
          type: "",
          big_title: "",
          small_title: "",
          img: "",
          link_type: 1,
          link: "",
          weight: 0,
          is_active: 1
        };
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/bestSelling/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleAdd() {
      this.form.type = this.listQuery.type || '';
      this.dialogVisible = true;
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleEdit(item) {
      this.form = {
        id: item.id,
        school_id: item.school_id,
        type: item.type,
        big_title: item.big_title,
        small_title: item.small_title,
        img: item.img,
        link_type: item.link_type,
        link: item.link,
        weight: item.weight,
        is_active: item.is_active
      };
      this.dialogVisible = true;
    },
    handleSelectionChange(val) {
      let ids = [];
      val.forEach(element => {
        ids.push(element.id);
      });
      this.ids = ids;
    },
    handleShow(show) {
      let text = show == 1 ? "上架" : "下架";
      this.$confirm("确定要" + text + "选中的选项吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/bestSelling/isActive",
          method: "post",
          data: {
            ids: this.ids,
            is_active: show
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleDel(item) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/bestSelling/del",
          method: "post",
          data: {
            ids: item ? [item.id] : this.ids
          }
        }).then(() => {
          // 删除最后一条数据时无数据问题
          this.list.length <= 1 ? this.listQuery.page-- : false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    saveData() {
      if (!this.school_id) {
        this.$message({
          type: "warning",
          message: "请选择学校"
        });
        return;
      } else {
        this.form.school_id = this.school_id;
      }
      if (!this.form.type) {
        this.$message({
          type: "warning",
          message: "请选择类型"
        });
        return;
      }
      if (!this.form.big_title) {
        this.$message({
          type: "warning",
          message: "请输入大标题"
        });
        return;
      }
      if (!this.form.img) {
        this.$message({
          type: "warning",
          message: "请上传图片"
        });
        return;
      }
      if (!this.form.link) {
        this.$message({
          type: "warning",
          message: "请输入链接"
        });
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/backend/bestSelling/save",
        method: "post",
        data: this.form
      }).then(() => {
        this.btnLoading = false;
        this.dialogVisible = false;
        this.getList();
        this.$message({
          type: "success",
          message: "操作成功!"
        });
      });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.floor-container {
  display: inline-block;
  width: 160px;
  height: 160px;
  margin-right: 25px;
  margin-bottom: 25px;
}
</style>
